/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback } from 'react';
import classnames from 'classnames';
import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import usePageContext from 'utils/hooks/usePageContext';
import useGetTargetProfile from 'utils/hooks/useGetTargetProfile';
import { useProfileAccesses } from 'utils/hooks/useAuthenticatedUser';

import { Trans, useTranslation } from 'src/i18n';
import { TP, BASE_PAGE_ROUTES, ENTITY_TYPE, PROFILE_TYPES } from 'constants/index';

import BannerDetailsDrawer from '../ConversionFlowBanners/BannerDetailsDrawer';
import { TYPE } from '../ConversionFlowBanners/ConversionFlowBanners';

import classes from './IntermediateBanners.module.scss';

const IntermediateBanners = ({ config: banner, isSmall }) => {
  const { t } = useTranslation('NS_CONVERSION_FLOW');
  const { isMobile } = useDeviceTypeLayouts();
  const [isOpen, setIsOpen] = useState(false);
  const { navigate, permissions } = usePageContext();
  const { profileData, entityType } = useGetTargetProfile();
  const { ownerProfile } = useProfileAccesses();
  const { isLoggedIn, castingToolPermissions } = permissions;

  const handleCheckPermission = useCallback(() => {
    if (!isLoggedIn) {
      setIsOpen(true);
      return;
    }

    if (banner?.type === TYPE.CASTING) {
      if (castingToolPermissions?.hasAccess) {
        const linkProps = navigate.getLinkProps({
          baseRoute: BASE_PAGE_ROUTES.CASTING,
          onlyLinkProps: true,
        });
        navigate.to(linkProps);
      } else {
        setIsOpen(true);
      }
    } else {
      if (profileData && entityType !== ENTITY_TYPE.PROFILE) {
        setIsOpen(true);
        return;
      }

      if ((profileData && entityType === ENTITY_TYPE.PROFILE) || ownerProfile?.profile?.id) {
        const isPro = profileData?.subscriptionStatus === PROFILE_TYPES.PRO;

        if (isPro) {
          const linkProps = navigate.getLinkProps({
            entityType: ENTITY_TYPE.ARTIST,
            entity: profileData.id ? profileData : ownerProfile?.profile,
            onlyLinkProps: true,
          });
          navigate.to(linkProps);
        } else {
          setIsOpen(true);
        }
      }
    }
  }, [
    isLoggedIn,
    banner?.type,
    castingToolPermissions?.hasAccess,
    navigate,
    profileData,
    entityType,
    ownerProfile?.profile,
  ]);
  if (!banner) return null;

  const handleOnClick = () => {
    handleCheckPermission();
  };

  if (banner?.type === TYPE.CASTING) {
    return (
      <>
        {isSmall || isMobile ? (
          <div className={classes.castingBannerSmall} onClick={handleOnClick}>
            <div className={classes.castingBannerSmall__textArea}>
              <Typography className={classes.castingBannerSmall__textArea_mainText}>
                {t(`${TP}.FN_CASTING`)}?
              </Typography>
              <div className={classes.castingBannerSmall__textArea_subText}>
                <Typography className={classes.title}>
                  <Trans
                    i18nKey={`${TP}.${banner.title}`}
                    components={{
                      br: <br />,
                    }}
                    ns="NS_CONVERSION_FLOW"
                  />
                </Typography>
                <Typography className={classes.subTitle}>{t(`${TP}.FN_CONVERSION_CASTING_BANNER_SUBTITLE`)}</Typography>
                <Typography className={classes.workTypes}>{t(`${TP}.FN_CONVERSION_CASTING_INTER_LIST`)}</Typography>
              </div>
              <Typography className={classes.subTitle}></Typography>
            </div>
            <LinkButton
              rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
              size="medium"
              styles={{ root: classes.castingBannerSmall__btn }}
              variant="text"
            >
              {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
            </LinkButton>
          </div>
        ) : (
          <div className={classes.castingBanner} onClick={handleOnClick}>
            <div className={classes.bgBlurredShade}></div>
            <div className={classes.castingBanner__leftBlock}>
              <Typography className={classes.title}>{t(`${TP}.FN_CASTING`)}?</Typography>
              <div className={classes.castingBanner__leftBlock_subTextBlock}>
                <Typography className={classes.subTitle}>
                  <Trans
                    i18nKey={`${TP}.${banner.title}`}
                    components={{
                      br: <br />,
                    }}
                    ns="NS_CONVERSION_FLOW"
                  />
                </Typography>
                <Typography className={classes.subTextBlock__otherText}>
                  {t(`${TP}.FN_CONVERSION_CASTING_BANNER_SUBTITLE`)}
                </Typography>
              </div>
            </div>
            <div className={classes.castingBanner__rightBlock}>
              <Typography className={classes.castingBanner__rightBlock_listText}>
                {t(`${TP}.FN_CONVERSION_CASTING_INTER_LIST`)}
              </Typography>
              <LinkButton
                rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
                size="medium"
                styles={{ root: classes.castingBanner__rightBlock_btn }}
                variant="text"
              >
                {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
              </LinkButton>
            </div>
          </div>
        )}
        {isOpen && (
          <BannerDetailsDrawer isOpen={isOpen} type="casting" onClose={() => setIsOpen(false)} showLoginText />
        )}
      </>
    );
  }

  if (banner?.type === TYPE.ORGANIZATION) {
    const renderMobileVersion = isSmall || isMobile;

    return (
      <>
        <div className={classes.organizationBanner} onClick={handleOnClick}>
          <div className={classes.bgBlurredShade}></div>
          <div className={classes.organizationBanner__leftBlock}>
            {!renderMobileVersion && (
              <Typography variant="p" className={classes.subTitle}>
                {t(`${TP}.FN_CONVERSION_CASTING_INTER_LIST`)}
              </Typography>
            )}
            <Typography variant="p" className={classes.title}>
              {renderMobileVersion ? t(`${TP}.${banner.mobileTitle}`) : t(`${TP}.${banner.desktopTitle}`)}
            </Typography>
          </div>
          <div className={classes.organizationBanner__rightBlock}>
            <LinkButton
              rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
              size="medium"
              styles={{ root: classes.organizationBanner__rightBlock_btn }}
              variant="text"
            >
              {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
            </LinkButton>
          </div>
        </div>
        {isOpen && (
          <BannerDetailsDrawer isOpen={isOpen} type="casting" onClose={() => setIsOpen(false)} showLoginText />
        )}
      </>
    );
  }

  return (
    <>
      {isSmall || isMobile ? (
        <div
          className={classnames(classes.artistBannerSmall, { [classes[banner.className]]: !!banner.className })}
          onClick={handleOnClick}
        >
          {!banner.hideShade && <div className={classes.bgBlurredShadeMobile} />}
          <div className={classes.artistBannerSmall__textArea}>
            <div className={classes.artistBannerSmall__textArea_mainText}>
              {banner.otherText && (
                <Typography className={classes.otherText}>{t(`${TP}.${banner.otherText}`)}</Typography>
              )}
              <Typography className={classes.title}>
                <Trans i18nKey={`${TP}.${banner.title}`} components={{ span: <span /> }} ns="NS_CONVERSION_FLOW" />
              </Typography>
            </div>
            <Typography variant="p" className={classes.subtitle}>
              {t(`${TP}.${banner.subTitle}`)}
            </Typography>
            {banner?.disclaimer && (
              <Typography variant="p" className={classes.disclaimer} size={10}>
                <Trans
                  i18nKey={`${TP}.${banner.disclaimer}`}
                  components={{ span: <span className={classes.starIcon} /> }}
                  ns="NS_CONVERSION_FLOW"
                />
              </Typography>
            )}
          </div>
          <LinkButton
            rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
            size="medium"
            styles={{ root: classes.artistBannerSmall__btn }}
            variant="text"
          >
            {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
          </LinkButton>
        </div>
      ) : (
        <div
          className={classnames(classes.artistBanner, { [classes[banner.className]]: !!banner.className })}
          onClick={handleOnClick}
        >
          <div className={classes.artistBanner__textWrapper}>
            <div className={classes.artistBanner__textWrapper_primaryText}>
              {banner.otherText && (
                <Typography className={classes.otherText}>{t(`${TP}.${banner.otherText}`)}</Typography>
              )}
              <Typography className={classes.title}>
                <Trans i18nKey={`${TP}.${banner.title}`} components={{ span: <span /> }} ns="NS_CONVERSION_FLOW" />
              </Typography>
            </div>
            <div className={classes.artistBanner__textWrapper_secondaryText}>
              <Typography size={12} className={classes.subTitle}>
                {t(`${TP}.${banner.subTitle}`)}
              </Typography>
            </div>
          </div>
          <LinkButton
            rightIcon={<SpriteIcon icon="chevron_right" size={20} />}
            size="medium"
            styles={{ root: classes.artistBanner__btn }}
            variant="text"
          >
            {t(`${TP}.FN_CONVERSION_TRY_NOW`)}
          </LinkButton>
        </div>
      )}
      {isOpen && <BannerDetailsDrawer isOpen={isOpen} type="artist" onClose={() => setIsOpen(false)} showLoginText />}
    </>
  );
};

export default IntermediateBanners;
